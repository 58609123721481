import status from '@/utils/status'
const format = {
	date: (data) => {
		let result = data;
		//react 가져온 포맷 형태로 인해 코드 추가
		if(data?.onlyDate) {
			result = data.date
			if(!data.date) {
				return ''
			}
		}
		if(!data) {
			return ''
		} 
		let current = new Date(result);
		let year = current.getFullYear();
		if(!year) {
			return data;
		}
		let month = current.getMonth() < 9 ? `0${current.getMonth()+ 1}` : current.getMonth() + 1;
		let day = current.getDate() < 10 ? `0${current.getDate()}` : current.getDate();
		let hour = current.getHours() < 10 ? `0${current.getHours()}` : current.getHours();
		let minutes = current.getMinutes() < 10 ? `0${current.getMinutes()}` : current.getMinutes();
		let dateFormat = `${year}-${month}-${day}`;
		let datetimeFormat = `${dateFormat} ${hour}:${minutes}`
		if(dateFormat.indexOf('1899') >= 0) {
			return ''
		}

		return data?.onlyDate != null && data?.onlyDate == false ?datetimeFormat : dateFormat;
	},
	number: (val) => {
		let result = val;
		if(typeof result === 'string') {
			result = val.replaceAll(',', '');
			result = Number(result)
		}
		return result > 0 ? result.toLocaleString() : 0;
		// let result = Number(val);
		// return result > 0 ? result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : result;
	},
	placeholder: (word) => {
		if(word) {
			let lastLetter = word[word.length - 1];
			let uni = lastLetter.charCodeAt(0);
			return `${word}${(uni - 44032) % 28 != 0 ? '을' : '를'} 입력해주세요`;
		}
	},
	phone: (input) => {
		if(!input) {
			return ''
		}
		const cleanInput = input.replaceAll(/[^0-9]/g, "");
		let result = cleanInput;
		const length = cleanInput.length;
		if(length === 8) {
			result = cleanInput.replace(/(\d{4})(\d{4})/, '$1-$2');
		} else if(cleanInput.startsWith("02") && (length === 9 || length === 10)) {
			result = cleanInput.replace(/(\d{2})(\d{3,4})(\d{4})/, '$1-$2-$3');
		} else if(!cleanInput.startsWith("02") && (length === 10 || length === 11)) {
			result = cleanInput.replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3');
		}
		return result;
	},
	new: (item, date) => {
		let standard = new Date(date);
		if((today - standard)/(60*60*1000) <= 24) {
			item.isNew = true
		} else {
			item.isNew = false
		}
		return item
	},
	flat: (data, orgKey) => {
		const keyList = orgKey.split(".");
		for(const key of keyList) {
			data = data[key] != null ? data[key] : '';
		}

		return data;
	},
	setFlat: (data, orgKey, value) => {
		let keyList = orgKey.split('.');
		const lastKey = keyList[keyList.length - 1];
		keyList = keyList.slice(0, keyList.length - 1);

		for(const key of keyList) {
			data = data[key];
		}

		data[lastKey] = value
		return data
	},
	dataValue: (key, item, isSummary) => {
		let value = format.flat(item, key)
		if(!value && value.length == 0) {
			return '-'
		}
		//입금 금액 부분만 제외
		if(isSummary && key === 'paidAmount') {
			return `${format.number(item.paidAmount)}원 / <span>${format.number(item.amount)}원</span>`
		} else if(isSummary && key === 'amount') {
			return `${format.number(item.amount - item.paidAmount)}원`
		}

		//상태
		if(key == 'status') {
			return status.text[value]
		//date format
		} else if (key.toLowerCase().indexOf('date') >= 0){
			return format.date({date: value, onlyDate: true})
		//phone
		} else if (key.toLowerCase().indexOf('phone') >= 0){
			return format.phone(value)
		} else if (key.toLowerCase().indexOf('fax') >= 0){
			return format.phone(value)
		//amount
		} else if (key.indexOf('mount') >= 0){
			return `${format.number(value)}원`
		} else {
			return value;
		}
	},
	highlighted: (text = '', highlight = '') => {
		let highlightText = ''; 
		if(typeof highlight === 'object') {
			highlightText = highlight?.text;
		} else {
			highlightText = highlight;
		}
		if (!highlightText?.trim()) {
			return `<span>${text}</span>`
		}
		const escapeRegExp = (str = '') => (
			str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')
		)
		const regex = new RegExp(`(${escapeRegExp(highlightText)})`, 'gi')
   		const parts = text.split(regex)

		let result = ''
		result += parts.filter(part => part).map((part, i) => {
			return regex.test(part) ? `<mark style="background-color: #FFE2E0;" key=${i}>${part}</mark>` : `<span key=${i}>${part}</span>`
		})
		result = result.replaceAll(',', '')
		return result;
	},
	regPw: (value) => {
		let result = true;
		var validatePassword = /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,16}$/
		if(!validatePassword.test(value)) {
			result = false;
		}
		return result;
	},
	type: (key) => {
		if(key.indexOf('Date') >= 0) {
			
		}
	},
	checkBatchimEnding(word) {
		if (typeof word !== 'string') return null;
		
		var lastLetter = word[word.length - 1];
		var uni = lastLetter.charCodeAt(0);
		
		if (uni < 44032 || uni > 55203) return null;
		
		return (uni - 44032) % 28 != 0 ? '을' : '를';
	},
	lower(val) {
		return val ? val.toLowerCase() : val;
	}
}

export default format